import React from "react";
import { makeStyles } from '@mui/styles'
import { Card, CardContent } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
	card: {
		maxWidth: 645,
		height: 450,
		width: '50%',
		margin: 5,
		[theme.breakpoints.down("md")]: {
         width: '100%'
      }
	},
	errorIcon: {
		fontSize: '8rem !important',
		color: '#413575 !important'
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		height: 450
	},
	errorMessage: {
		color: '#413575 !important',
		fontFamily: "'Nunito', sans-serif !important",
		fontSize: '2.5rem !important',
		textAlign: 'center',
		fontWeight: 900
	}
}))
const NoRouteFound = () => {
	const css = useStyles();
	return (
		<div className={css.root} id="error-404">
			<Card className={css.card}>
				<CardContent className={css.cardContent}>
					<ErrorIcon className={css.errorIcon}/>
					<div className={css.errorMessage}>
						Oops! Page Not Found
					</div>
				</CardContent>
			</Card>
      </div>
	);
}

export default NoRouteFound;