import React from "react";
import { makeStyles } from '@mui/styles'
import { Card, CardContent } from "@mui/material";
import Terms from "./Terms";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: '100%',
    // margin: '0 auto',
    height: '100%',
    width: '100%',
    margin: 5,
    [theme.breakpoints.down("md")]: {
      width: '100%'
    }
  },
  cardContent: {
    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'column',
    // alignItems: 'center',
    // alignContent: 'center',
    // height: '100%'
  },
  errorMessage: {
    color: '#413575 !important',
    fontFamily: "'Nunito', sans-serif !important",
    fontSize: '1.3rem !important',
    textAlign: 'justify'
  },
  topHeader: {
    color: '#413575',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '2.3rem',
    textShadow: '2px 0px black',
    fontWeight: 900,
    textAlign: 'center'
  },
  subheader: {
    color: '#413575',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.3rem',
    textAlign: 'left',
    fontWeight: 900
  },
  pros_cons: {
    color: '#413575',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.1rem',
    textAlign: 'left',
    fontWeight: 900
  },
  header: {
    color: '#413575',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.8rem',
    textAlign: 'left',
    fontWeight: 900,
    marginTop: 20,
    textDecoration: 'underline'
    // marginBottom: 20
  },
  textBody: {
    color: '#413575',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.15rem',
    textAlign: 'justify',
    marginTop: 10,
    fontWeight: 600
  },
  div: {
    marginTop: 10,
    marginLeft: 10
  },
  a: (props) => ({
    color: '#634ad0',
    fontStyle: 'italic',
    // ,
    // "&:visited": {
    //   color: '#413575'
    // },
    "&:hover": {
      color: '#413575'
    }
  })
}))
const Soldout = () => {
  const css = useStyles();
  const Href = (link, text) => {
    // return <Link href={link} color='#634ad0' underline='none' rel="noopener noreferrer" target="_blank">{text}</Link>
    return <span><a href={link} target="_blank" rel="noopener noreferrer" className={css.a}>{text}</a></span>
  }
  return (
    <div className={css.root} id="tc-at-home">
      <Card className={css.card}>
        <CardContent className={css.cardContent}>
          <div className={css.topHeader}>Supplies you need to TC at Home</div>
          <div className={css.subheader} style={{ textAlign: 'center', marginTop: 20 }}>I am not affiliated with any of the below labs. This is just a list I’ve put together to help you all start tissue culturing on your own. Please do your research and understand the risks involved in tissue culturing at home. </div>
          <div className={css.textBody} style={{ textAlign: 'center' }}>
            <span>
              I want to start by saying before doing anything, I really think you should read at least one of the following books:
            </span>
            {Href("https://amzn.to/3KuRqDC", " Plants in Test Tubes")}
            <span> OR </span>
            {Href("https://amzn.to/3s4etyW", " Plant Tissue Culture: A Home-Based Guide.")}
          </div>
          <div className={css.header}>Hormones and Media:</div>
          <div className={css.div}>
            <span className={css.subheader}>MS: </span>
            <span className={css.textBody}>MS is required in every step of tissue culture regardless of what you are working on. Any kind will do but I really like {Href("https://phytotechlab.com/murashige-skoog-basal-medium-with-vitamins-sucrose-gelzan.html", "this one")} as it has everything I need for aroids. It also has gelzan mixed in it, so you do not have to use agar. Gelzan is a gelling agent that is clearer than agar so you can see any contamination a little better.</span>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Indole Butyric Acid: </span>
            <span className={css.textBody}>Also known as IBA. IBA is the main hormone I use in the rooting stage.  This is a bit more expensive than the powder form. This is already diluted so you will have one less step to mix media and one less step to worry about causing contamination.</span>
            <div>
              {Href("https://phytotechlab.com/indole-3-butyric-acid-solution-1-mg-ml.html", "Link 1")}
            </div>
            <div>
              {Href("https://caissonlabs.com/product/indole-3-butyric-acid-iba-solution-2/", "Link 2")}
            </div>
            <div>
              {Href("https://phytotechlab.com/indole-3-butyric-acid-iba.html", "Powder form link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Naphthaleneacetic Acid: </span>
            <span className={css.textBody}>
              Also known as NAA. This is another hormone that is used to root stage and used in the initiation stage. Again, this is the solution and not the powder form which is a bit more expensive but also easier for beginners.
            </span>
            <div>
              {Href("https://phytotechlab.com/alpha-naphthaleneacetic-acid-naa-solution-1-mg-ml.html", "Link 1")}
            </div>
            <div>
              {Href("https://caissonlabs.com/product/naphthaleneacetic-acid-naa-solution/", "Link 2")}
            </div>
            <div>
              {Href("https://phytotechlab.com/naphthaleneacetic-acid-k-naa.html", "Powder form link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Benzlyaminopurine: </span>
            <span className={css.textBody}>
              Also known as BAP or BA. This is the hormone in keiki paste. It is the main hormone I use in the multiplication stage. This is also the liquid solutions which is more expensive.
            </span>
            <div>
              {Href("https://phytotechlab.com/6-benzylaminopurine-ba-solution-1-mg-ml.html", "Link 1")}
            </div>
            <div>
              {Href("https://caissonlabs.com/product/6-benzylaminopurine-6-ba-ba-bap-solution/", "Link 2")}
            </div>
            <div>
              {Href("https://phytotechlab.com/6-benzylaminopurine-ba.html", "Powder form link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Antioxidant Mixture: </span>
            <span className={css.textBody}>
              This is not a requirement to have. Adding this to your media will help eliminate contamination.
            </span>
            <div>
              {Href("https://phytotechlab.com/antioxidant-mixture.html", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Tween20: </span>
            <span className={css.textBody}>
              This is also not a requirement, but it is a great disinfectant solution to use when sterilizing your plants.
            </span>
            <div>
              {Href("https://phytotechlab.com/polyoxyethylenesorbitan-tween-reg-20.html", "Link 1")}
            </div>
            <div>
              {Href("https://caissonlabs.com/product/polysorbate-20-tween-20/", "Link 2")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>PPM: </span>
            <span className={css.textBody}>
              This isn’t a requirement, but I highly suggest getting some. You can add it to your media to prevent contamination. If contamination is caught quickly enough, you can add a couple drops of this to kill it off. PPM is said to slow the growth rate of plantlets, but it also helps with contamination.
            </span>
            <div>
              {Href("https://caissonlabs.com/product/plant-preservative-mixture-ppm/", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>PH Up and Down: </span>
            <span className={css.textBody}>
              Your media needs to be around 5.5-5.9. To do that, you will use pH up and down to get it there after you have mixed it all up. If you use leca, you may already have this stuff. I have only ever used pH down when I’ve added too much pH up. If you have this, already, that will probably be the only one you will have to replace.
            </span>
            <div>
              {Href("https://amzn.to/39wV7fv", "Link 1")}
            </div>
            <div>
              {Href("https://amzn.to/3F0AqnH", "PH up link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>PH Reader: </span>
            <span className={css.textBody}>
              I have not found one that I really love. I have several and they all seem to be off a little or stop working after a while. I have added the cheapest one I could find for you all. I have bought a few of the expensive ones and they are equally as crappy.
            </span>
            <div>
              {Href("https://amzn.to/3LADhGy", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Cleaning Chemicals: </span>
            <span className={css.textBody}>
              Speaking of cleaning, bleach, rubbing alcohol, and hydrogen peroxide are REQUIRED to tissue culture. Keep a couple bottles of each in the area you’re working in. Please DO NOT EVER mix these together. It will create a hazardous gas that will make you sick! I’ve linked some down below, but it is definitely cheaper to buy them at the grocery store.
            </span>
            <div>
              {Href("https://amzn.to/3LyjgAm", "Bleach")}
            </div>
            <div>
              {Href("https://amzn.to/3vtfimQ", "Hydrogen Peroxide")}
            </div>
            <div>
              {Href("https://amzn.to/3F3ZI9K", "Rubbing/Isopropyl Alcohol")}
            </div>
            <span className={css.textBody}>
              Other cleaning products that aren’t required but you may be interested in:
              This is a disinfectant spray I use to spray down my area before I’m working. There is an automatic sprayer you can purchase for this spray. It is not a requirement, but I’ve found it helpful.
            </span>
            <div>
              {Href("https://amzn.to/3rYdpfF", "Disinfectant")}
            </div>
            <div>
              {Href("https://amzn.to/3ksyKde", "Automatic Sprayer")}
            </div>
            <span className={css.textBody}>
              Most bottles are NOT bleach safe. After using them with bleach and other harsh chemicals, they will deteriorate and stop spraying. I’ve found these to last so far with bleach.
            </span>
            <div>
              {Href("https://amzn.to/3vuOSRS", "Chemical spray bottles")}
            </div>
          </div>
          <div className={css.header}>Acclimation Products:</div>
          <div className={css.div}>
            <span className={css.subheader}>Physan: </span>
            <span className={css.textBody}>
              I would say Physan is a requirement to have on hand. It is great for cleaning tools, plants, and basically everything. It’s a virucides, bactericide, fungicide, and algaecide. I use this for prepping the plants to go into tissue culture and during the acclimation process when I notice there is an issue.
            </span>
            <div>
              {Href("https://amzn.to/3Kx10ps", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Captan: </span>
            <span className={css.textBody}>
              Captan is a must have in my opinion. When acclimating plants, this is what I use to clean them prior to adding them to a soilless media. I use 1 teaspoon per Quart of water.
            </span>
            <div>
              {Href("https://amzn.to/3OKbS6X", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Iodine: </span>
            <span className={css.textBody}>
              You can add a couple drops of iodine in my captan mixture. This isn’t a requirement, but I will always keep this on hand. I do want to mention, too much iodine will cause the leaves of the plants to turn black so only use a couple drops. You can get this at the grocery store. This also helps with any contamination.  Linked below is the one I’ve been using.
            </span>
            <div>
              {Href("https://amzn.to/3FgxBzb", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Soilless Substrate: </span>
            <span className={css.textBody}>
              Everyone uses different methods to acclimate their plants. I’ve seen people use coco coir, fluval stratum, perlite, and moss. My personal favorite is 2 parts coco coir, 2 parts perlite and 1 part horticultural sand. I’ve found that moss causes a lot of contamination and melting in my experience. At this point, I’ve only used stratum and perlite for orchid TCs. I’ve linked all the products below and notes about them if needed.
            </span>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Coco coir: </span>
            <span className={css.textBody}>
              Maybe it’s just me but I feel like this is a steal for coco coir. The bricks look really small but they end up a really nice size.
            </span>
            <div>
              {Href("https://amzn.to/3OLpQ8C", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Perlite: </span>
            <span className={css.textBody}>
              you want a small perlite as the plants and roots are very small. You can get this wherever but here is the perlite I’m using.
            </span>
            <div>
              {Href("https://amzn.to/3koVXgi", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Horticultural sand: </span>
            <span className={css.textBody}>
              I’ve tried so many different types of sand and honestly this is my favorite one. You don’t want the sand too small as it will make the substrate really dense, but you don’t want it too large as it will cause the plants to be too airy. The plantlets need to stay moist during acclimation. If you have a Home Depot near you, I have found this brand at Home Depot. It is about the same price on Amazon as it is at Home Depot.
            </span>
            <div>
              {Href("https://amzn.to/39liKHv", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Moss: </span>
            <span className={css.textBody}>
              As I said before, I don’t really use moss for tissue culture but here’s a link of the moss I use for regular propagations just in case you don’t want to go look for your own.
            </span>
            <div>
              {Href("https://amzn.to/3F1UcIE", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Fluval Stratum: </span>
            <span className={css.textBody}>
              I’ve seen a few people use this recently with lots of success. I’ve used it on my orchid props but as of right now nothing else. I may come back later and update this. If you don’t know what fluval stratum is, think of tiny leca.
            </span>
            <div>
              {Href("https://amzn.to/3kp7mwE", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Rooting Hormone: </span>
            <span className={css.textBody}>
              Any rooting hormone with IBA or NAA will work. The larger amount of either of these hormones, the higher the chance of contamination growing in your plantlets and potentially killing them. Remember fungi and bacteria grow faster than plantlets and adding hormones to the plants can cause issues. If you aren’t familiar with contamination, I would start with something that has a low dose like Hormex #1. If you have some experience and have plantlets without roots, you could go up to Hormex #8 or #16.
            </span>
            <div>
              {Href("https://amzn.to/38CiUtG", "Hormex variety pack")}
            </div>
            <div>
              {Href("https://amzn.to/3LITusY", "Hormex single pack")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Mycorrhiza: </span>
            <span className={css.textBody}>
              I’ve had many people ask me if this is a rooting hormone. No, it is not a rooting hormone but it does help with the roots. Mycorrhiza is a fungus that is associated with the roots of plants. You’re probably reading this and thinking, “Ash, you have gone on and on about fungi being bad for TC, why is this on the list?” Well yes, fungi are bad for plants in tissue culture but once acclimating plants, you will want to introduce them to beneficial fungi and bacteria. Mycorrhiza helps plants by attaching to the roots and then helps it obtain water and nutrients from the soil. Remember a couple years back when monstera obliqua was such a mysterious, hard to obtain and grow plant? This was the monstera obluqua collectors secret to getting them to grow. I’ve linked 2 below. The Great White I put directly on the plant when taking it out of the flask. The Bushdoctor microbe Brew I then use in the water as a low grade fertilizer when acclimating.
            </span>
            <div>
              {Href("https://amzn.to/3y6fUD", "Bushdoctor")}
            </div>
            <div>
              {Href("https://amzn.to/3F5U8OX", "Great White")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Shelf: </span>
            <span className={css.textBody}>
              You will want a shelf system to set your trays (and possibly your supplies on) I use the shelving system below. You can hang lights on them easily and adjust the shelves to suite your needs. Any shelf would do.
            </span>
            <div>
              {Href("https://www.homedepot.com/p/HDX-Chrome-5-Tier-5-Tier-Metal-Wire-Shelving-Unit-36-in-W-x-72-in-H-x-16-in-D-21656CPS/205885523", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Lights: </span>
            <span className={css.textBody}>
              These lights do not fit the above shelf. I am just going to let you know that right now. I use the 4ft lights and just let them hang off the sides a little or go onto the next shelf. If you get two shelves and put them side by side, you can get a set of 4ft lights and a set of 2ft lights and then it fits perfectly across the two shelves. These are Barrina T8 grow lights so if you have some of these, you don’t have to buy more. I have them 10-12 inches above my plants and they have only ever burned the Philodendron white wizard and Florida ghost.
            </span>
            <div>
              {Href("https://amzn.to/3vuUmvW", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Water Filter: </span>
            <span className={css.textBody}>
              A water filter isn’t necessary but I prefer to eliminate the new plants from having any negative affects to the minerals in my water. If you have good water, you may not need this. I wash the gel off with filtered water and water the plantlets with filtered water. If you have water on your fridge, you can put that in jugs and use it instead when the water reaches room temperature.
            </span>
            <div>
              {Href("https://amzn.to/3F0wzad", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Hygrometer: </span>
            <span className={css.textBody}>
              This is a requirement. You won’t need them to check humidity levels until you start taking the domes off but I do think you should keep temps between 70-85 degrees. The plants will grow better that way in my opinion. Once you remove the domes, it is very important to monitor the humidity level.
            </span>
            <div>
              {Href("https://amzn.to/38AlAYU", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Planting tools: </span>
            <span className={css.textBody}>
              These tools are optional, but I LOVE them. When putting plants into the coco coir mix, I just poke a little hole with the one tool. If I need a little more substrate, the mini shovel tool scoops the perfect amount. The shovel tool and the Y tool are also very helpful for pushing the substrate down around the tiny plantlets.
            </span>
            <div>
              {Href("https://amzn.to/3MJNjVZ", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginTop: 20 }}>
            <span className={css.textBody} style={{ fontWeight: 900 }}>
              For the indecisive people out there, here is a large seedling kit than the one above but smaller quantity than the one below. I will say per item, it is more expensive than the items below but it cost less money upfront.
            </span>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Larger Seedling Kit: </span>
            <span className={css.textBody}>
              Whether you are just testing the waters, or you have decided you are going to jump right in, I’ve added supplies for you below. The first options are smaller, affordable options. The larger options aren’t expensive, it’s just a lot.
            </span>
            <div>
              {Href("https://amzn.to/3KpPySV", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Small start seeding tray: </span>
            <span className={css.textBody}>
              This small seedling tray set comes with domes, clear seedling pots and a tray to hold a water reservoir for the seedling pots. This is an affordable option before investing in the more expensive items.
            </span>
            <div>
              {Href("https://amzn.to/3vQpEMJ", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Heat mat w/ thermostat: </span>
            <span className={css.textBody}>
              This is a single heat mat that comes with a thermostat. Heat mats can cause a lot of issues with tissue culture plants. They can burn the roots and the warmth paired with humidity can cause the perfect environment for fungal growth. I would highly recommend starting out with a thermostat.
            </span>
            <div>
              {Href("https://amzn.to/3OG3o0u", "Link")}
            </div>
          </div>
          <div className={css.div}>
            <span className={css.subheader}>Heat mat for 2 seedling trays: </span>
            <span className={css.textBody}>
              This option will fit 2 large trays (below) or possibly all the small trays (above) onto a heat mat however you will have to buy a thermostat separately.
            </span>
            <div>
              {Href("https://growerssolution.com/products/2-tray-seedling-heat-mats-hydrofarm-heavy-duty?_pos=9&_sid=812e14fdb&_ss=r", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginTop: 20 }}>
            <span className={css.textBody} style={{ fontWeight: 900 }}>
              For those of you ready to jump in, the next 3 products are for you. The large domes fit the large trays and you can add 50 pots in each tray.
            </span>
            <div>
              {Href("https://growerssolution.com/products/7-inch-propagation-humidity-dome-5-pack?_pos=4&_sid=c8ceaa332&_ss=r", "Large Domes")}
            </div>
            <div>
              {Href("https://growerssolution.com/products/1020no?_pos=4&_sid=2b4b8765f&_ss=r", "Large Trays")}
            </div>
            <div>
              {Href("https://growerssolution.com/collections/small-greenhouse-pots/products/2-5-black-deep-square-greenhouse-pots-p64", "2.5 inch pots – 800 pack")}
            </div>
          </div>
          <div className={css.header}>Lab Equipment:</div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.textBody}>
              First, you will need to decide where you are going to be working. Are you going to use your kitchen counter, your dining room table, or maybe set up in a spare bedroom. Having a clean surface is extremely important. If you are going to buy a table just for this, I would suggest stainless steel table. They aren’t cheap but they are easy to clean, and you can cut plants up right on the surface.  Things like wood are porous and can harbor bacteria so keep that in mind if you are using your dining table. If you aren’t going to be buying a new table, you may want to just invest in a stainless steel cutting board. I like to use them as an added barrier surface and switch them out between plants instead of cleaning the entire table again. They are easy to clean and sterile.
            </span>
            <div>
              {Href("https://amzn.to/3kwtZ24", "Stainless steel cutting boards")}
            </div>
          </div>
          <div className={css.header} style={{ fontSize: '1.5rem', marginLeft: 10 }}>Autoclave Options:</div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Microwave: </span>
            <span className={css.textBody}>
              I know you probably have a microwave you use weekly to heat up your food. Using the microwave you cook your food in to cook chemicals in is not exactly safe though – just think about the chemicals you are using and then cooking where you cook your food. On top of that, you will have to deep clean your microwave anytime you want to add any tissue culture items to prevent contamination. I got my tissue culture microwave on Facebook marketplace for $5. It was basically brand new. I strongly suggest you get one just for your tissue culture things. You will use the microwave to cook your media in place of an autoclave. Some people use this to sterilize their flasks and tools. I personally find it doesn’t sanitize flasks enough and you cannot put your scalpels and other metal objects in the microwave. For this reason, I’ve added the next two items below.
            </span>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Instantpot: </span>
            <span className={css.textBody}>
              You can sometimes find these at thrift stores. You will want to make sure it has the Sterilize button on it. For your tools, you will wrap them in tinfoil and fold it so water does not directly touch your tools. The bigger the size, the less time it takes to clean everything.
            </span>
            <div>
              {Href("https://amzn.to/3ksLfp8", "6 QT")}
            </div>
            <div>
              {Href("https://amzn.to/3EZLt0g", "8 QT")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Pressure Cooker/Canner: </span>
            <span className={css.textBody}>
              This is probably the best option if I’m being honest. You can get much larger pressure cooker than you can an instant pot which make it easier to sterilize everything at once. Make sure it has a gauge on it. Once you hit 15 PSI, you will want to cook everything for 20 mins. I’ve added a few of the cheapest options that had high ratings below for you to choice from. You do not need an instant pot and a pressure cooker; one or the other will do. Prices may slightly vary depending on state and sales. I’ve heard the Pesto brand is the best.
            </span>
            <div>
              {Href("https://amzn.to/3KwJySf", "23 QT for $139")}
            </div>
            <div>
              {Href("https://amzn.to/3kuZR7v", "16 QT for $127")}
            </div>
          </div>
          <div className={css.header} style={{ fontSize: '1.5rem', marginLeft: 10 }}>Sterile Cabinet/Flow Hood:</div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.textBody}>
              I’m going to be honest; nothing beats a flow hood. You can get less contamination with a sterile box but to rid contamination from the air, you will need a flow hood. There are many flow hoods for under $1,000 that say they are HEPA certified on Amazon, but none of those will work for tissue culture. Flow hoods are expensive, and I really don’t have any advice to give on how to find one that isn’t the price of a car. I will try to help with some alternatives though.
            </span>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Storage Tote: </span>
            <span className={css.textBody}>
              This is absolutely an option, but you will need to rig it. It is much cheaper to purchase this outside of Amazon. You can decide what size works best for you. You will want to close off most the opening to keep contaminated air from coming in. You will want to make sure it’s big enough for your magnetic stirrer, chemicals, plantlets, etc. as you do not want to be moving in and out of your box as you work.
            </span>
            <div>
              {Href("https://amzn.to/3LAtiB4", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Sterile Box: </span>
            <span className={css.textBody}>
              This is a better option but also more expensive. It breaks down so it is a better option if you have limited space. A flow hood is the best option, but they are insanely expensive.
            </span>
            <div>
              {Href("https://amzn.to/3MJBwqK", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Tent: </span>
            <span className={css.textBody}>
              If you have the space, adding a grow tent for an extra layer of protection is the absolute way to go! Ro and I both have tents set up in our spaces just to have that extra barrier. Below is the exact tent I have.  After adding a table and stool you have just enough space to add your plants and store your media. I wouldn’t go any smaller than this and really I don’t think you have to go bigger than this either unless you are adding a really large table. I am really proud of myself that I figured out the perfect size tent the first time.
            </span>
            <div>
              {Href("https://amzn.to/3y2kShE", "Link")}
            </div>
          </div>
          <div className={css.header}>Additional Items needed:</div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Magnetic Stirrer: </span>
            <span className={css.textBody}>
              This is an ABSOLUTE requirement unless you are ok with stirring/shaking for upwards of an hour. This is the nicest, most affordable option I’ve found.
            </span>
            <div>
              {Href("https://amzn.to/3MISpBw", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Magnets: </span>
            <span className={css.textBody}>
              If you tend to lose things, plan to work on a couple plants at a time, or don’t like to constantly wash dishes, go ahead and get an extra pack of magnets for your magnetic stirrer. In under a year, I’ve ordered 2 packs of 24.
            </span>
            <div>
              {Href("https://amzn.to/37SyGAN", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Bunsen Burner: </span>
            <span className={css.textBody}>
              Please note this is an open flame. Using alcohol near this can start a fire if you are not careful. This or the next item are a requirement to clean your tools between each use.
            </span>
            <div>
              {Href("https://amzn.to/3ksRk4Y", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Induction Knife Heater: </span>
            <span className={css.textBody}>
              This is a less hazardous option. I’ve been seeing more and more labs switch to using this device. You leave it in a few passes until you forceps or scalpels turn red and then take it out to let it cool.
            </span>
            <div>
              {Href("https://amzn.to/3OMrCWS", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Scale: </span>
            <span className={css.textBody}>
              This is used to weigh your powders and media. Any scale that can measure down to .001g and up to 50g will do. If you bought the solutions that I mentioned above, you will only be measuring your MS.
            </span>
            <div>
              {Href("https://amzn.to/3F1KexG", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Tinfoil: </span>
            <span className={css.textBody}>
              Don’t forget the tinfoil. You will use this to wrap your tools to clean. I’ve added 500ft of tinfoil just in case you want to buy bulk otherwise get it at the grocery store.
            </span>
            <div>
              {Href("https://amzn.to/3s1CKp6", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Forceps: </span>
            <span className={css.textBody}>
              Any stainless steel forceps will do. Make sure to get longer ones. You may think you would like small ones, but I’ve found everyone wishes they had some long ones. I like the set below as there are a few different sizes and then having a few of the second link as they have a curved edge. The curved edged ones make it easy to put plants into media.
            </span>
            <div>
              {Href("https://amzn.to/3rXBaVp", "Set")}
            </div>
            <div>
              {Href("https://amzn.to/3s1q6GN", "Curved")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Vessel Tape: </span>
            <span className={css.textBody}>
              It doesn’t matter what flasks/vessels you decide on, get you some of this. It will help seal the flask and prevent contamination especially if you have a problem with touching your flasks. You can use cling wrap but after you cut it all up, you have touched it with your hands and cutting tools and have had to pull it apart multiple times. It is just much easier to buy it this way.
            </span>
            <div>
              {Href("https://caissonlabs.com/product/vessel-wrap-sealing-tape/", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Containers: </span>
            <span className={css.textBody}>
              If you are not working by a sink, you need a couple large containers with lids to dump your mixtures as you clean plants. Again, you do NOT want to mix chemicals like bleach, alcohol, and hydrogen peroxide so a container labeled for each would be your best bet! I use these and dump them after I wrap up each time. You can probably find something that would work and is much cheaper at Dollar Tree or Walmart.
            </span>
            <div>
              {Href("https://amzn.to/3kso1iL", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Gloves: </span>
            <span className={css.textBody}>
              I know you may think cleaning your hands well may help but until you learn the sterilization process, eliminating one less thing from causing contamination can be so important. If you decide to get other gloves, make sure that they are individually wrapped and sterile. It is pointless to use gloves that are not sterile and individually wrapped. Remember, there is germs and stuff under your fingernails even if you wash your hands well.
            </span>
            <div>
              {Href("https://amzn.to/3vMDWxO", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Hand sanitizer: </span>
            <span className={css.textBody}>
              You will absolutely need hand sanitizer. Any time you touch something, you will be using this so make sure to get some with aloe. You also may want to invest in some good hand cream too. Us tissue culturist like to say, “if your hands aren’t cracked and raw when you are done, you didn’t sanitize enough!” I didn’t really understand that saying until I started tissue culturing at home.
            </span>
            <div>
              {Href("https://amzn.to/38zlMro", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Respirator Mask: </span>
            <span className={css.textBody}>
              REQUIREMENT! Seriously this is the first thing you should buy. Not only do you not want to inhale the possibly hazardous fungi and bacteria spores you most definitely are going to create in your home lab, but you also don’t want to inhale all the chemicals you are going to be using. I have accidentally knocked over my bleach, alcohol and peroxide while trying to keep papers from falling onto the open flame of my Bunsen Burner and gassed myself. That’s the second time I’ve gassed myself actually. Now, the first thing I do before going into the lab is put on my mask. Seriously, if you take anything at all from this, please know that you need a respirator mask. A little N95 mask you wore out and about all 2020 is NOT GONNA CUT IT.
            </span>
            <div>
              {Href("https://amzn.to/3LJ5Y3O", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Syringes: </span>
            <span className={css.textBody}>
              Both of these are requirements. When you are mixing media, you want to use a 100mL syringe to remove the media as it stirs and place it into flasks. I thought I could get away with using 20 or even 50mL syringes but just go ahead and get the 100mL ones. Make sure they are individually wrapped. The small increment packs, you will use sometimes when adding or moving plants in flasks. Everyone prefers a different size so I though a multi pack would be good for you all to start with.
            </span>
            <div>
              {Href("https://amzn.to/3KxhiPj", "Small increment multi pack")}
            </div>
            <div>
              {Href("https://amzn.to/3OMgdGE", "100mL")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Pyrex/glass measuring cups: </span>
            <span className={css.textBody}>
              I started out with the dollar tree 1000mL measuring cups. Those cups can not be sterilized properly. The pyrex glass measuring cups can be put in the instant pot and properly sterilized. You will need at least 1 1000mL cup (in increments of 50s) to mix media and 1 to use for cleaning plants. You will want one that goes up to 100mL to properly measure out chemicals when cleaning plants. You can use syringes for the second part but unless you are buying $100+ syringes, you will not be able to autoclave those.
            </span>
            <div>
              {Href("https://amzn.to/3kpJOYH", "1000mL")}
            </div>
            <div>
              {Href("https://amzn.to/3vun3Jh", "Mix")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Tote: </span>
            <span className={css.textBody}>
              I know this is listed above but I am listing it again because you will be using it for a different reason. Since you are doing this at home, I feel it is really important to have your flasks that contain plants in a closed off space. I know they will be in flasks with sealant tape but I can not stress enough how sanitary things need to be to keep them sterile. Adding either a tote or the next item would help keep things sterile. You do not want them just sitting on an open shelf even in a spare room. There are fungi and bacteria floating in the air that get pushed around by the HVAC units. There are fungi and bacteria that live on us even when we shower it’s still there. It is so important to keep the flasks as clean as possible.
            </span>
            <div>
              {Href("https://amzn.to/3LAtiB4", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Closed Cabinet: </span>
            <span className={css.textBody}>
              If you would rather have something nice sitting out and you can afford it, a closed cabinet would work in place of a tote. I would skip the wood as it is porous so can harbor bacteria. You would set it up the same way you would set up the greenhouse cabinents essentially. I have 2 detolfs and I hate them. They are too small and the light options suck. They work for their purpose though. I use the glass shelves as there really needs to be no airflow. I’ve added the sealant strips as the next item which you would use to seal the door to keep air out.
            </span>
            <div>
              {Href("https://www.ikea.com/us/en/p/milsbo-glass-door-cabinet-white-00396416/#content", "Link")}
            </div>
            <div>
              {Href("https://www.ikea.com/us/en/p/rudsta-glass-door-cabinet-anthracite-50450137/", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 10 }}>
            <span className={css.subheader}>Silicone Strips and Sealant: </span>
            <span className={css.textBody}>
              If you decide to go with the cabinet, you will want to get seal strips and possibly some silicone sealant as well. This will seal off the airflow from getting to your flasks.
            </span>
            <div>
              {Href("https://amzn.to/3F4lqVG", "Strips")}
            </div>
            <div>
              {Href("https://amzn.to/3vEceoh", "Sealant")}
            </div>
          </div>
          <div className={css.header} style={{ fontSize: '1.5rem', marginLeft: 10, marginTop: 10 }}>Flasks:</div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.textBody}>
              There are many different types of flasks. You just really must find the one you like and that works for you. There are some pros and cons to them which I’ll list out for you.
            </span>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Amazon Deli containers: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>Easy to get; fast and free shipping; decent quantity; room for plants to grow;</span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>Lids melt pretty easily which cause them to not close properly; not clear like glass/hard to see in; pop top;
                </span>
              </div>
            </span>
            <div>
              {Href("https://amzn.to/3KyfDcb", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Amazon Test Tubes: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Easy to get; fast and free shipping; great quality; 50 pieces; mostly clear; durable and won’t melt; screw top;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Not much room for plants to grow which is a huge problem in the multiplication step; lid is not clear; label wears off quickly.
                </span>
              </div>
            </span>
            <div>
              {Href("https://amzn.to/3y25GBn", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Amazon square TC containers: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Made for TC; will not melt; free shipping; sturdy; room for plants to grow; clear;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Expensive; must buy multiple packs ; takes longer to get to you;
                </span>
              </div>
            </span>
            <div>
              {Href("https://amzn.to/38BDlai", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Amazon Vent Top TC Containers: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Made for mushroom cultivar; will not melt; free shipping; durable; screw top;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Not as clear as glass/hard to see in; lids are difficult to get on; filter can hold contamination if not cleaned properly; expensive; must buy multiple packs;
                </span>
              </div>
            </span>
            <div>
              {Href("https://amzn.to/3MCt5x2", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Phytotech Glass culture vessels: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  CLEAR; last a long time; will not melt; made for TC; room to grow; screw top;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Must buy multiple packs; shipping is expensive; lids are not clear;
                </span>
              </div>
            </span>
            <div>
              {Href("https://phytotechlab.com/culture-vessel-glass-70-mm-8-oz-240-ml.html", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Phytotech Flip Top: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Cheap; larger quantity; durable; sturdy; made for TC;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Will melt above 249° F (121° C); not clear like glass; expensive shipping; pop on top;
                </span>
              </div>
            </span>
            <div>
              {Href("https://phytotechlab.com/culture-tube-flip-cap-sterile.html", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>PhytoTech SteriCon: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Clear;  larger quantity; made for TC;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Expensive shipping; CAN NOT be autoclaved/ one time use;
                </span>
              </div>
            </span>
            <div>
              {Href("https://phytotechlab.com/culture-vessel-stericon-trade-8.html", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>PhytoTech PTcon screwtops: </span>
            <span className={css.textBody}>
              <span>These are my favorite right now.</span>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Super durable; screw top; large quanities; last a long time; made for TC; lots of room to grow;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Not clear like glass; will melt above 249° F/121° C (I haven’t had a problem with this); expensive shipping;
                </span>
              </div>
            </span>
            <div>
              {Href("https://phytotechlab.com/culture-vessel-ptcon-trade-7.html", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>PhytoTech Deli Containers: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Not screwtop; cheap; sturdier than the ones from amazon; autoclavable; made for TC;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Pop on top; not as clear as glass (better than others though); durable but will eventually break down;
                </span>
              </div>
            </span>
            <div>
              {Href("https://phytotechlab.com/i-phyto-i-con-8-oz-237-ml.html", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Caisson Disposable Deli Cups: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Great size; sterilized by gamma radiation; cheap; get free samples of products; made for TC;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Not reusable; expensive shipping;
                </span>
              </div>
            </span>
            <div>
              {Href("https://caissonlabs.com/product/disposable-vessel/", "Link")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Caisson Square Vessel: </span>
            <span className={css.textBody}>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Great size; sturdy and durable; will not melt; made for TC;  free samples of other products;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Initial cost; expensive shipping; lids are not clear;
                </span>
              </div>
            </span>
            <div>
              {Href("https://caissonlabs.com/product/ga-7-case-of-100/", "Link 1")}
            </div>
            <div>
              {Href("https://caissonlabs.com/product/ga-7-3-case-of-100/", "Link 2")}
            </div>
          </div>
          <div className={css.div} style={{ marginLeft: 20 }}>
            <span className={css.subheader}>Uline Glass jars: </span>
            <span className={css.textBody}>
              <span>These I have not tried but Ro suggested them. Make sure to get the plastic lids. You can get whatever size you want but 8oz is what we all normally use. </span>
              <div>
                <span className={css.pros_cons}>Pros: </span>
                <span>
                  Glass jars so they are see through; different sizes available; fairly cheap; durable; autoclavable;
                </span>
              </div>
              <div>
                <span className={css.pros_cons}>Cons: </span>
                <span>
                  Expensive shipping; tops are not see through;
                </span>
              </div>
            </span>
            <div>
              {Href("https://www.uline.com/BL_8199/Straight-Sided-Glass-Jars?keywords=glass+jars", "Link")}
            </div>
          </div>
          <Terms mt={50}/>
        </CardContent>
      </Card>
    </div>
  );
}

export default Soldout;