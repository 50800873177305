import React from "react";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
   socialIcon: {
      width: '70px', height: '70px'
   }
}))
const SocialIcons = () => {
   const css = useStyles();
   return (
      <div style={{textAlign: 'center'}}>
         <a href="https://www.tiktok.com/@atlplantroom" target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/assets/tiktok.png'} className={css.socialIcon} alt="TikTok" title="TikTok" />
         </a>
         <a href="https://www.facebook.com/groups/873960649975130/" target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/assets/facebook.png'} className={css.socialIcon} alt="Facebook" title="Facebook" rel="noopener noreferrer" />
         </a>
      </div>
   )
}

export default SocialIcons;