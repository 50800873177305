import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles'
import { Button, Collapse, IconButton, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as Scroll } from 'react-scroll'
import SocialIcons from "./SocialIcons";

const useStyles = makeStyles((theme) => ({
	appBar: {
		background: 'none !important'
	},
	icon: {
		color: '#413575',
		fontSize: '2rem !important',
	},
	goDown: {
		color: '#413575',
		fontSize: '4rem !important',
	},
	appBarTitle: {
		fontFamily: "'Nunito', sans-serif",
		flexGrow: 1,
		color: '#413575 !important',
		textShadow: '2px 0px black'
	},
	appBarWrapper: {
		width: '100%',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'space-between'
	},
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh'
	},
	title: {
		color: '#413575',
		fontFamily: "'Nunito', sans-serif",
		fontSize: '2.3rem',
		textShadow: '2px 0px black',
		fontWeight: 900
	},
	desc: {
		color: '#413575',
		fontFamily: "'Nunito', sans-serif",
		fontWeight: 900,
		fontSize: '1.2rem',
		width: '70%',
		margin: '0 auto',
		padding: '15px',
		textAlign: 'justify',
		[theme.breakpoints.down("md")]: {
			width: '100%'
		}
	},
	titleContainer: {
		textAlign: 'center',
	},
	a: props => ({
		color: '#634ad0',
		fontSize: '1.5rem',
		textDecoration: 'none',
		"&:hover": {
			color: '#413575'
		}
	}),
	btn: {
		color: 'white !important',
		fontWeight: 'bold !important',
		borderColor: '#413575 !important',
		borderWidth: '1.5px !important',
		textTransform: 'none !important',
		fontSize: '1.2rem !important',
		background: '#413575 !important',
		fontFamily: "'Nunito', sans-serif !important",
	}
}))

const Header = _ => {
	const css = useStyles();
	const [animating, setAnimating] = useState(false)
	useEffect(_ => {
		setAnimating(true)
	}, [])
	return (
		<div id="body" className={css.root}>
			<Collapse
				in={animating}
				timeout={animating ? 1500 : 0}
				style={{ marginTop: 50 }}
			>
				<div className={css.titleContainer}>
					<h1 className={css.title}>AtlPlantRoom</h1>
					<div className={css.desc}>
						<Grid container justifyContent='center' rowSpacing={1} columnSpacing={1}>
							<Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/HomeTCSupplies" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>TCatHome</Button>
								</a>
							</Grid>
							<Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/greatseedrace" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>GreatSeedRace</Button>
								</a>
							</Grid>
							{/* <Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/mediakit" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>MediaKit</Button>
								</a>
							</Grid> */}
						</Grid>
						<div style={{ marginTop: 10 }}>
							<span style={{ fontSize: '1.5rem' }}>Tissue Cultured Philodendron Joepii Batch Eury: </span>
							<span>You’re ordering a tissue cultured Philodendron Joepii. The batch name is Eury. These were removed from flask on 4/15/2022. They will be shipped out July 11th and 18th. I will not be holding them so please make sure that someone is available that week to pick it up. They will go out in the order they were purchased. Please make sure your address on PayPal is correct prior to purchase. The address does not change after you purchase on my end. A handling fee will be applied if the address is incorrect after purchase. There is a ONE plant per household limit. Anyone who tries to order more than one will have their orders cancelled. There will be more in the future in which you will be able to purchase again. Plants will need to stay in the pots they are shipped in. Please do not remove the plants from their pots or change the soil for at least 2 months unless roots start to come out of the bottom of the pot. It is very important to keep them in these pots as tissue culture plants product very thin, fragile roots for the first few months of the acclimation period. This is a grower’s choice selection which means I will select one of the plants in the photo to send to you the week of July 11th. I will not be sending updates individually. Please check my Tiktok (linked below) or the Facebook group for weekly updates.
							</span>
						</div>
						<SocialIcons />
					</div>
					<Scroll to="plant-to-visit" smooth={true} duration={900}>
						<IconButton>
							<ExpandMoreIcon className={css.goDown} />
						</IconButton>
					</Scroll>
				</div>
			</Collapse>
		</div>
	)
}
export default Header;