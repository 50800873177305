const desc = 'Click on the button below to place your order'
const title =  <a href="https://www.tiktok.com/tag/GreatSeedRace?lang=en" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', color: '#413575'}}>#GreatSeedRace</a>
const basePath = process.env.PUBLIC_URL + '/assets/'
const GreatSeed = [
   {
      title: title,
      desc: desc,
      imageUrl: basePath + 'greatseed1.png',
   }
]

export default GreatSeed;