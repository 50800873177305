import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles'
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import CountUp from 'react-countup';
import { VictoryPie } from "victory";
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import Link from '@mui/icons-material/Link';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	ComposableMap,
	Geography,
	Marker,
	Geographies
} from 'react-simple-maps';
import { createTheme, ThemeProvider } from "@mui/material";
const theme = createTheme({
	typography: {
		fontFamily: "'Poppins', sans-serif"
	}
})
const img = process.env.PUBLIC_URL + '/assets/', red = '#fb3c27', green = '#005f00', lightGreen = '#8ee867', lightGreen1 = '#c5ffa0', pink = '#fabdb7'
const Item = styled('div')(({ theme }) => {
	return ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.primary,
		lineHeight: 1.6
	})
});
const useStyles = makeStyles((theme) => ({
	desc1: {
		color: red,
		fontSize: '2.5rem !important',
		fontWeight: '700',
		margin: 0, padding: 0,
		[theme.breakpoints.down("sm")]: {
			fontSize: '2rem !important'
		},
		[theme.breakpoints.down("md")]: {
			fontSize: '2rem !important'
		}
	},
	desc2: {
		color: green,
		fontSize: '1.5rem !important',
		fontWeight: '500',
		"& p": {
			margin: 0,
		}
	},
	desc3: {
		color: green,
		fontSize: '1.5rem !important',
		fontWeight: '500',
		"& p": {
			margin: 0,
		}
	},
	socialIcon: {
		width: '70px', height: '70px',
		margin: 10
	},
	socialIconlt: {
		width: '200px',// height: '50px'
		margin: 10,
		[theme.breakpoints.up("xs")]: {
			marginBottom: 20
		}
	},
	counter1: {
		color: lightGreen,
		fontSize: 70,
		fontWeight: 'bold',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			fontSize: 140
		},
		[theme.breakpoints.between(320, 400)]: {
			fontSize: 60
		}
	},
	followersIcon: {
		width: '100px',
		[theme.breakpoints.down('sm')]: {
			width: '50px'
		},
		[theme.breakpoints.between(320, 400)]: {
			width: '40px'
		}
	},
	followersLabel: {
		color: green,
		fontSize: '5rem',
		fontWeight: 'bold',
		marginTop: '-60px',
		[theme.breakpoints.between('xs', 'sm')]: {
			fontSize: '3rem',
			marginTop: '-30px'
		}
	},
	views: {
		color: green,
		fontSize: 40,
		fontWeight: 'bold',
		borderRightColor: green,
		borderRight: 4,
		borderRightStyle: 'dotted',
		[theme.breakpoints.between('xs', 'sm')]: {
			border: 0
		}
	},
	views1: { fontWeight: '500', fontSize: '1.5rem' },
	DIY: {
		width: '220px',
		[theme.breakpoints.between('xs', 'sm')]: {
			width: '200px'
		}
	},
	aboutImg: {
		width: '60%',
		[theme.breakpoints.between('xs', 'sm')]: {
			width: '100%'
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	}
}))
const MediaKit = () => {
	localStorage.clear()
	const css = useStyles();
	const [viewsIsAnimated, setViewsIsAnimated] = useState(false);
	const [followerIsAnimated, setFollowerIsAnimated] = useState(false);
	const [likesIsAnimated, setLikesIsAnimated] = useState(false);
	const [commentsIsAnimated, setCommentsIsAnimated] = useState(false);
	const followerRef = useRef(null);
	const viewsRef = useRef(null);
	const likesRef = useRef(null);
	const commentsRef = useRef(null);
	const onIntersection = (hook, ref) => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					hook(true);
					observer.disconnect();
				}
			});
		});
		observer.observe(ref);
	}
	useEffect(() => {
		onIntersection(setFollowerIsAnimated, followerRef.current)
		onIntersection(setViewsIsAnimated, viewsRef.current)
		onIntersection(setLikesIsAnimated, likesRef.current)
		onIntersection(setCommentsIsAnimated, commentsRef.current)
	}, []);
	const geoUrl = process.env.PUBLIC_URL + '/countries.json'
	const ByCountry = ({ country, color }) => {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
				<Box component='span' sx={{ width: 25, height: 25, backgroundColor: color, borderRadius: '50%' }}></Box><Box component='span' sx={{ ml: 1, color: green, fontWeight: '700', fontSize: '1.5rem' }}>{country}</Box>
			</Box>
		)
	}
	const Collaboration = ({ name, isLinked, isDotted, link }) => {
		return (
			<Box sx={{ textAlign: 'left' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					{isLinked ? <div style={{ marginTop: '6px' }}><a href={link} target='_blank' rel="noreferrer"><Link sx={{ fontSize: 40, color: red }} /></a></div> : <Box sx={{ width: 40 }} />}
					{isDotted ? <FiberManualRecordIcon sx={{ fontSize: 15, color: green }} /> : <Box sx={{ width: 12 }} />}
					<Box sx={{ ml: 2, fontSize: '1.7rem', color: green, fontWeight: '700' }}>{name}</Box>
				</Box>
			</Box>
		)
	}
	return (
		<ThemeProvider theme={theme}>
			<Grid container rowSpacing={1} columnSpacing={1} sx={{ background: '#fff' }} justifyContent='center'>
				<Grid item md={12} sm={12} lg={6} xs={12}>
					<Item>
						<Box><img alt="" src={img + 'mediakit.png'} width={300} /></Box>
						<Box className={css.desc1} sx={{ fontSize: '3.2rem !important' }}>I'm Ashley aka</Box>
						<Box className={css.desc1} sx={{ fontSize: '3.2rem !important' }}>ATLPlantRoom</Box>
						<Box><img alt="" src={img + 'mediakit0.png'} width={300} /></Box>
						<Box className={css.desc3}>Houseplant Educator and Influencer</Box>
						<Box>
							<a href="https://www.tiktok.com/@atlplantroom?lang=en" target="_blank" rel="noopener noreferrer">
								<img src={img + 'tt.png'} className={css.socialIcon} alt="TikTok" title="TikTok" />
							</a>
							<a href="https://www.instagram.com/atlplantroom/" target="_blank" rel="noopener noreferrer">
								<img src={img + 'insta.png'} className={css.socialIcon} alt="Instagram" title="Instagram" />
							</a>
							<a href="https://www.youtube.com/channel/UC5ptoz9ctDVYadBbszw6GNw" target="_blank" rel="noopener noreferrer">
								<img src={img + 'yt.png'} className={css.socialIcon} alt="Instagram" title="Instagram" />
							</a>
							<a href="https://linktr.ee/atlplantroom" target="_blank" rel="noopener noreferrer">
								<img src={img + 'lt.png'} className={css.socialIconlt} alt="Instagram" title="Instagram" />
							</a>
						</Box>
					</Item>
				</Grid>
				<Grid item md={8} sm={8} lg={6} xs={12}>
					<Item sx={{ backgroundColor: green, borderRadius: '5px', padding: '2px 8px' }}>
						<video controls style={{ width: '100%', borderRadius: '5px', height: '650px', backgroundColor: 'black' }}>
							<source src={img + 'videos/v1.mp4'} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
						{/* <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@atlplantroom/video/7188346370292501803" data-video-id="7188346370292501803" style={{ maxWidth: '605px', minWidth: '300px' }} >
						<section style={{ color: green, fontWeight: 'bold' }}>
							<a target="_blank" title="@atlplantroom" href="https://www.tiktok.com/@atlplantroom?refer=embed" style={{ color: green }}>@atlplantroom</a>
							Finally got my order from @tropicals_plants I really like this company. This is the 2nd order I’ve got from them.
							<a title="importedplants" target="_blank" href="https://www.tiktok.com/tag/importedplants?refer=embed" style={{ color: green }}>#importedplants</a>
							<a title="plantunboxing" target="_blank" href="https://www.tiktok.com/tag/plantunboxing?refer=embed" style={{ color: green }}>#plantunboxing</a>
							<a title="planthaul" target="_blank" href="https://www.tiktok.com/tag/planthaul?refer=embed" style={{ color: green }}>#planthaul</a>
							<a title="plantmail" target="_blank" href="https://www.tiktok.com/tag/plantmail?refer=embed" style={{ color: green }}>#plantmail</a>
							<a target="_blank" title="♬ original sound - ATLPlantRoom" href="https://www.tiktok.com/music/original-sound-7188346486168505130?refer=embed" style={{ color: green }}>♬ original sound - ATLPlantRoom</a>
						</section>
					</blockquote> */}
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={12} xs={12}>
					<Item>
						<Box className={css.counter1}>
							<Box><img alt="" src={img + 'mediakit1.png'} className={css.followersIcon} /></Box>
							<div ref={followerRef}>
								<CountUp start={25550} separator="," end={26000} duration={2} delay={0} useEasing={false} redraw={followerIsAnimated} style={{ verticalAlign: 'super' }} />
							</div>
							<Box><img alt="" src={img + 'mediakit2.png'} className={css.followersIcon} /></Box>
						</Box>
						<Box className={css.followersLabel}>Followers</Box>
					</Item>
				</Grid>
				{/* Views, Likes, Comments starts*/}
				<Grid item md={3} sm={4} lg={3} xs={12}>
					<Item>
						<Box className={css.views}>
							<div ref={viewsRef}>
								<CountUp start={200000} separator="," end={220000} duration={2} delay={0} useEasing={false} redraw={viewsIsAnimated} suffix='+' />
							</div>
							<Box className={css.views1}>Weekly views</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item md={3} sm={4} lg={3} xs={12}>
					<Item>
						<Box className={css.views}>
							<div ref={likesRef}>
								<CountUp start={0} separator="," end={8000} duration={2} delay={0} useEasing={false} redraw={likesIsAnimated} suffix='+' />
							</div>
							<Box className={css.views1}>Likes average</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item md={3} sm={4} lg={3} xs={12}>
					<Item>
						<Box className={css.views} sx={{ border: 0 }}>
							<div ref={commentsRef}>
								<CountUp start={0} separator="," end={1000} duration={2} delay={0} useEasing={false} redraw={commentsIsAnimated} suffix='+' />
							</div>
							<Box className={css.views1}>Weekly comments</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item md={9} sm={9} lg={9} xs={9} justifyContent='center' sx={{ borderBottom: 4, borderBottomStyle: 'dotted', borderBottomColor: green, mt: 5 }}></Grid>
				{/* Views, Likes, Comments ends */}
				<Grid item md={5} sm={5} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item>
						<Box><img alt="" src={img + 'mediakit3.png'} className={css.DIY} /></Box>
						<Box className={css.desc3}>
							<p>DIY content, product review and breakdown, UGC, tissue culture, plant care and education, interviews, collection and nursery tours</p>
						</Box>
					</Item>
				</Grid>
				<Grid item md={5} sm={5} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item>
						<Box><img alt="" src={img + 'mediakit4.png'} style={{ width: '250px' }} /></Box>
						<Box className={css.desc1} sx={{ color: green, }}>I expect to hear from you soon!</Box>
						<Box className={css.desc2}>atlplantroom@gmail.com</Box>
						<Box className={css.desc2}>@atlplantroom</Box>
					</Item>
				</Grid>
				{/* About Me */}
				<Grid item md={5} sm={5} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item>
						<Box><img alt="" src={img + 'mediakit5.png'} className={css.aboutImg} /></Box>
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item>
						<Box className={css.desc1} sx={{ color: green, }}>ABOUT ASHLEY</Box>
						<Box className={css.desc2}>Ashley has a true passion for plants. She has a large appetite to learn the hobby and her passion has given her a wide variety of skills. She's learned Indonesian to better communicate with overseas sellers and collectors. She has a special interest in chemicals and ingredients used in fertilizers, pesticides and other plant products which paved a foundation for her to become a self-taught tissue culturist.</Box>
					</Item>
				</Grid>
				{/* Demographic Audiense */}
				<Grid item md={12} sm={12} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item>
						<Box className={css.desc1} sx={{ color: green, }}>DEMOGRAPHIC AUDIENCE</Box>
						<Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
							<Box sx={{ borderBottom: 15, width: '50%', margin: 'auto', borderColor: pink }}></Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', mt: 5, justifyContent: 'space-around' }}>
							<Box className={css.desc1} sx={{ color: green, mt: 5, fontSize: '2.1rem !important' }}>Gender</Box>
							<Box>
								<Box>
									<FemaleIcon sx={{ color: green, fontSize: 100 }} />
								</Box>
								<Box>
									<Box sx={{ fontWeight: '700', fontSize: '1.5rem', color: green }}>75%</Box>
									<Box sx={{ fontWeight: '700', fontSize: '1.5rem', color: green }}>Women</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<MaleIcon sx={{ color: green, fontSize: 100 }} />
								</Box>
								<Box>
									<Box sx={{ fontWeight: '700', fontSize: '1.5rem', color: green }}>25%</Box>
									<Box sx={{ fontWeight: '700', fontSize: '1.5rem', color: green }}>Male</Box>
								</Box>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 5 }}>
							<Box className={css.desc1} sx={{ color: green, mt: 5, fontSize: '2.1rem !important' }}>Age</Box>
							<Box>
								<VictoryPie
									data={[
										{ x: 1, y: 24, label: "18 - 24\n24%" },
										{ x: 2, y: 43, label: "25 - 34\n43%" },
										{ x: 3, y: 21, label: "35 - 44\n21%" },
										{ x: 4, y: 12, label: `45+\n12%` }
									]}
									width={500} height={500}
									animate={{ duration: 100, easing: "bounce" }}
									colorScale={["#ff513b", "#ef0059", "#b70068", "#71006e"]}
									style={{ labels: { fontSize: 25, fill: 'white', fontFamily: 'unset' } }}
									// labelPlacement='perpendicular'
									labelRadius={100}
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', mt: 5, justifyContent: 'space-around' }}>
							<Box className={css.desc1} sx={{ color: green, mt: 5, fontSize: '2.1rem !important' }}>Countries</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<ByCountry country='76% United States' color='#FF5733' />
								<ByCountry country='5% Canada' color='#3399FF' />
								<ByCountry country='4% United Kingdom' color='#9933FF' />
							</Box>
						</Box>
						<Box>
							<ComposableMap>
								<Geographies geography={geoUrl}>
									{({ geographies }) =>
										geographies.map((geo) => (
											<Geography key={geo.rsmKey} geography={geo} fill={lightGreen}
												stroke="#FFFFFF" style={{
													default: { fill: lightGreen, outline: "none" },
													hover: { fill: green, outline: "none" },
													pressed: { fill: "#02A", outline: "none" },
												}} />
										))
									}
								</Geographies>
								<Marker coordinates={[-98.58333, 39.83333]}>
									<circle cx={0} cy={0} r={10} fill="#FF5733" />
								</Marker>
								<Marker coordinates={[-106.3468, 56.1304]}>
									<circle cx={0} cy={0} r={10} fill="#3399FF" />
								</Marker>
								<Marker coordinates={[-2.8085, 55.0234]}>
									<circle cx={0} cy={0} r={10} fill="#9933FF" />
								</Marker>
							</ComposableMap>
						</Box>
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={5} xs={12} sx={{ mt: 5 }}>
					<Item sx={{ backgroundColor: lightGreen1, borderRadius: 1 }}>
						<Box className={css.desc1} sx={{ color: green, }}>PREVIOUS COLLABORATIONS</Box>
						<Collaboration name='MonsteraX' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7132654923258416426?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
						<Collaboration name='Perfect Choice Nursery' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7184907639434857771?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
						<Collaboration name='Aroid Greenhouses' isLinked={false} isDotted={true} />
						<Collaboration name='Leal Plants' isLinked={false} isDotted={true} />
						<Collaboration name='SouthEast Plant Shows' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7105863361820233002?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
					</Item>
					<Item sx={{ backgroundColor: pink, borderRadius: 1, mt: 1 }}>
						<Box className={css.desc1} sx={{ color: green, }}>TOURS AND INTERVIEWS</Box>
						<Collaboration name='Dr. Block' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7171961800919960875?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
						<Collaboration name='Barry Schwartz' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7170813449289600302?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
						<Collaboration name='Justin Hancock with' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7190407632270806314?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
						<Collaboration name='Costa Farms' isLinked={false} isDotted={false} />
						<Collaboration name='MarketBotany' isLinked={true} isDotted={true} link='https://www.tiktok.com/@atlplantroom/video/7166078299205127467?is_from_webapp=1&sender_device=pc&web_id=7193802352126871086' />
					</Item>
				</Grid>
				{/* How I Got Started */}
				<Grid item md={12} sm={12} lg={5} xs={12}>
					<Item>
						<Box className={css.desc1} sx={{}}>HOW I GOT STARTED</Box>
						<Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
							<Box sx={{ borderBottom: 15, width: '50%', margin: 'auto', borderColor: pink }}></Box>
						</Box>
						<Box className={css.desc2} sx={{ mt: 3 }}>
							I started collecting houseplants after my grandma passed and left me her last plant - a golden pothos. I spent countless hours researching how to care for it and what products to use. This sparked my interest in collecting different types of houseplants. I decided to build the plant room to house my ever growing collection. I had not been able to find anyone with an indoor plant room so I decided to share what I was doing online so others wanting a plant room had something to reference. Between the process of building the plant room and wanting to share all the interesting facts I found while researching plants, the @ATLPlantRoom Tiktok account was born.
						</Box>
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={5} xs={12}>
					<Item>
						<Box><img alt="" src={img + 'mediakit6.png'} style={{ width: '100%', borderRadius: 5 }} /></Box>
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={5} xs={12}>
					<Item>
						<video controls style={{ width: '100%', borderRadius: '5px', height: '500px', backgroundColor: 'black' }}>
							<source src={img + 'videos/v2.mp4'} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
						{/* <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@atlplantroom/video/7110597471595924782" data-video-id="7110597471595924782" style={{ maxWidth: '605px', minWidth: '300px' }} >
						<section style={{ color: green, fontWeight: 'bold' }}>
							<a target="_blank" title="@atlplantroom" href="https://www.tiktok.com/@atlplantroom?refer=embed" style={{ color: green }}>@atlplantroom</a>
							Who knew it was more than just a pot the plant came in and some water every now and then 🤷‍♀️
							<a title="plantparenthood" target="_blank" href="https://www.tiktok.com/tag/plantparenthood?refer=embed" style={{ color: green }}>#plantparenthood</a>
							<a title="plantparentlife" target="_blank" href="https://www.tiktok.com/tag/plantparentlife?refer=embed" style={{ color: green }}>#plantparentlife</a>
							<a target="_blank" title="♬ пон - 🤡" href="https://www.tiktok.com/music/пон-7026673812255099650?refer=embed" style={{ color: green }}>♬ пон - 🤡</a>
						</section>
					</blockquote> */}
					</Item>
				</Grid>
				<Grid item md={12} sm={12} lg={5} xs={12}>
					<Item>
						<Box className={css.desc1} sx={{}}>BLOG AUDIENCE</Box>
						<Box className={css.desc2} sx={{ fontWeight: 'bold' }}>My most frequent visitors</Box>
						<Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
							<Box sx={{ borderBottom: 15, width: '50%', margin: 'auto', borderColor: pink }}></Box>
						</Box>
						<Box className={css.desc2} sx={{ mt: 3 }}>
							My following consists of people who have a basic
							understanding of plants already but want to take
							their knowledge to the next level. This is no longer a
							phase or hobby for them; plants are a part of their
							everyday life. They know their plants' scientific
							names and have purchased lights and set-ups for
							them. They are the intermediate and expert level
							plant parent.
						</Box>
					</Item>
				</Grid>
				<Grid container justifyContent='center'>
					<Grid item md={12} sm={12} lg={5} xs={12} sx={{ backgroundColor: pink }}>
						<Item sx={{ backgroundColor: pink }}>
							<Box className={css.desc3} sx={{ fontWeight: 'bold' }}>What I want for my channel</Box>
							<Box sx={{ width: '100%', textAlign: 'center', mt: 6 }}>
								<Box sx={{ borderBottom: 15, width: '50%', margin: 'auto', borderColor: green }}></Box>
							</Box>
							<Box className={css.desc1} sx={{ mt: 6, color: green, }}>
								<Box>FUTURE OF MY</Box>
								<Box>CHANNEL</Box>
							</Box>
						</Item>
					</Grid>
					<Grid item md={12} sm={12} lg={5} xs={12} sx={{ backgroundColor: pink }}>
						<Item sx={{ backgroundColor: pink }}>
							<Box className={css.desc2} sx={{ fontSize: '1.3rem !important' }}>My mission is to create a community that enjoys plants as much as I do. I want to continue to share my experience, connections, and research with my community so we can all grow together. In the future, I hope to get more into conservation work by educating the public about endangered species and repopulating those species. I'd also like to find more environmentally friendly ways to grow plants and show the world there isn't just one way to grow happy houseplants.</Box>
						</Item>
					</Grid>
					<Grid item md={12} sm={12} lg={10} xs={12} sx={{ backgroundColor: pink }}>
						<Item sx={{ backgroundColor: pink }}>
							<Box className={css.desc1} sx={{ backgroundColor: pink, textAlign: 'center', color: green, }}>
								Ashley | @ATLPlantRoom
							</Box>
						</Item>
					</Grid>
				</Grid>

			</Grid>
		</ThemeProvider>
	);
}
export default MediaKit;