import React from "react";
import { makeStyles } from '@mui/styles'
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import Main from "./components/Main";
const theme = createTheme({
	typography: {
		fontFamily: "'Nunito', sans-serif"
	}
})
const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/bg.webp"})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center center'
	}
}))
const App = _ => {
	const css = useStyles();
	return (
		<ThemeProvider theme={theme}>
			<div className={css.root}>
				<CssBaseline />
				<Main />
			</div>
		</ThemeProvider>
	)
}
export default App;