import React from "react";
const css = {backgroundColor: '#41357587', color: 'white', borderRadius: '5px', padding: '10px'}
function Terms({mt}) {
	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: mt, height: 50 }}>
				<div style={{display: 'flex', width: '300px', justifyContent: 'space-around'}}>
					<div><a style={css} href="https://atlplantroom.com/privacy_policy.html">Privacy Policy</a></div>
					<div><a style={css} href="https://atlplantroom.com/terms.html">Terms & Conditions</a></div>
				</div>
			</div>
		</>
	);
}

export default Terms;