import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Soldout from './Soldout'
import TCatHome from './TCatHome'
import Home from './Home'
import NoRouteFound from './NoRouteFound'
import GreatSeedRace from './GreatSeedRace';
import MediaKit from './MediaKit';

const Main = () => {
   return (
      <Routes>
         <Route path='/' element={<Home />}></Route>
         <Route exact path='/soldout' element={(<Soldout />)}></Route>
         <Route exact path='/HomeTCSupplies' element={(<TCatHome />)}></Route>
         <Route exact path='/greatseedrace' element={(<GreatSeedRace />)}></Route>
         <Route exact path='/mediakit' element={(<MediaKit />)}></Route>
         <Route path='*' element={<NoRouteFound />}></Route>
      </Routes>
   );
}

export default Main;