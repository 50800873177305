import React from "react";
import Header from "./Header";
import Plant from "./Plant";
import Plants from "../static/Plants";
import Terms from "./Terms";

const PayPal = _ => {
   return (
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
         <input type="hidden" name="cmd" value="_s-xclick" />
         <input type="hidden" name="hosted_button_id" value="NN74VF54LACVS" />
         <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
         <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>
   )
}
function Home() {
   return (
      <>
         <Header />
         <Plant Plants={Plants} PayPal={PayPal} />
         <Terms mt={100}/>
      </>
   );
}

export default Home;