import React from "react";
import { makeStyles } from '@mui/styles'
import { Card, CardContent } from "@mui/material";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SocialIcons from "./SocialIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 645,
    height: 400,
    width: '50%',
    margin: 5,
    [theme.breakpoints.down("md")]: {
      width: '100%'
    }
  },
  errorIcon: {
    fontSize: '8rem !important',
    color: '#413575 !important'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    height: 400
  },
  errorMessage: {
    color: '#413575 !important',
    fontFamily: "'Nunito', sans-serif !important",
    fontSize: '1.3rem !important',
    textAlign: 'center',
    fontWeight: 900
  }
}))
const Soldout = () => {
  const css = useStyles();
  return (
    <div className={css.root} id="error-404">
      <Card className={css.card}>
        <CardContent className={css.cardContent}>
          <SentimentNeutralIcon className={css.errorIcon} />
          <div className={css.errorMessage}>
            Sorry, we are sold out. Continue following my social medias to get the latest updates as well as to learn when more are available
          </div>
          <SocialIcons/>
        </CardContent>
      </Card>
    </div>
  );
}

export default Soldout;