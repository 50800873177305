import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles'
import { Button, Collapse, IconButton, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as Scroll } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
	appBar: {
		background: 'none !important'
	},
	icon: {
		color: '#413575',
		fontSize: '2rem !important',
	},
	goDown: {
		color: '#413575',
		fontSize: '4rem !important',
	},
	appBarTitle: {
		fontFamily: "'Nunito', sans-serif",
		flexGrow: 1,
		color: '#413575 !important',
		textShadow: '2px 0px black'
	},
	appBarWrapper: {
		width: '100%',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'space-between'
	},
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh'
	},
	title: {
		color: '#413575',
		fontFamily: "'Nunito', sans-serif",
		fontSize: '2.3rem',
		textShadow: '2px 0px black',
		fontWeight: 900
	},
	desc: {
		color: '#413575',
		fontFamily: "'Nunito', sans-serif",
		fontWeight: 900,
		fontSize: '1.2rem',
		width: '70%',
		margin: '0 auto',
		padding: '15px',
		[theme.breakpoints.down("md")]: {
			width: '100%'
		}
	},
	titleContainer: {
		textAlign: 'center',
	},
	a: props => ({
		color: '#413575',
		textDecoration: 'none',
		"&:hover": {
			color: '#5a4a9f'
		}
	}),
	btn: {
		color: 'white !important',
		fontWeight: 'bold !important',
		borderColor: '#413575 !important',
		borderWidth: '1.5px !important',
		textTransform: 'none !important',
		fontSize: '1.2rem !important',
		background: '#413575 !important',
		fontFamily: "'Nunito', sans-serif !important",
	}
}))

const Header = _ => {
	const css = useStyles();
	const [animating, setAnimating] = useState(false)
	useEffect(_ => {
		setAnimating(true)
	}, [])
	return (
		<div id="body" className={css.root}>
			<Collapse
				in={animating}
				timeout={animating ? 1500 : 0}
				style={{ marginTop: 50 }}
			>
				<div className={css.titleContainer}>
					<h1 className={css.title}>AtlPlantRoom</h1>
					<div className={css.desc}>
						<Grid container justifyContent='center' rowSpacing={1} columnSpacing={1}>
							<Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>Go Back</Button>
								</a>
							</Grid>
							<Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/HomeTCSupplies" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>TCatHome</Button>
								</a>
							</Grid>
							{/* <Grid item md={3} sm={3} lg={3} xs={12}>
								<a href="/mediakit" className={css.a}>
									<Button sx={{ width: '100%' }} variant="contained" className={css.btn}>MediaKit</Button>
								</a>
							</Grid> */}
						</Grid>
						<div style={{ marginTop: 10, textAlign: 'center' }}>
							<div style={{ fontSize: '2rem', marginTop: 20 }}>
								<a href="https://www.tiktok.com/tag/GreatSeedRace?lang=en" target="_blank" rel="noopener noreferrer" className={css.a} style={{ textDecoration: 'underline' }}>
									#GreatSeedRace
								</a>
							</div>
							<div>
								Thank you so much for your interest in the Great Seed Race.  The seeds come from an Anthurium Brownii that was grown outside. The paternal plant is unknown. It is likely to be self-pollenated, warocqueanum, cupulispathum or moodeanum as those were the plants growing nearby with inflos. Below is a photo of the mother plant.
							</div>
							<div style={{ fontSize: '2rem', marginTop: 20, textDecoration: 'underline' }}>Rules</div>
						</div>
						<div style={{ textAlign: 'left', marginTop: '-15px' }}>
							<ul>
								<li>You must be in the USA to play and have Tiktok.</li>
								<li><span>To participate, post a video on Tiktok once a week on Tuesday with the hashtag </span><a href="https://www.tiktok.com/tag/GreatSeedRace?lang=en" target="_blank" rel="noopener noreferrer" className={css.a} style={{ textDecoration: 'underline' }}>
									#GreatSeedRace
								</a>.<span> I understand some major holidays are in the middle of the race and sometimes life happens, if you miss posting a Tuesday or two, you will not be disqualified if you post within the week.</span></li>
								<li>Please measure with the ruler provided. You do not have to measure every week just when things change and as your plant grows.</li>
								<li>You are free to grow your seed however you want. That means you can use any substrate, light, humidity, fertilizer, or whatever else comes to mind.</li>
								<li>There will be a prize for the largest seedling grown as well as the nicest anthurium. The voting will begin on Jan 10th  for the prettiest/nicest anthurium grown. The winners will be announced on January 17th.</li>
								<li>The prize is $50 off their next purchase with me for each of the two winners.</li>
							</ul>
						</div>
					</div>
					<Scroll to="plant-to-visit" smooth={true} duration={900}>
						<IconButton>
							<ExpandMoreIcon className={css.goDown} />
						</IconButton>
					</Scroll>
				</div>
			</Collapse>
		</div>
	)
}
export default Header;