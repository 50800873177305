import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Collapse, Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 645,
		margin: '0 auto'
	},
	media: {
		height: 650,
		objectFit: 'unset !important'
	},
	title: {
		fontFamily: "'Nunito', sans-serif !important",
		color: '#413575 !important',
		fontWeight: '900 !important'
	},
	collapse: {
		width: '100%',
		margin: '0 auto'
	}
}))

const ImageCard = ({ plants, animation, PayPal }) => {
	const css = useStyles()
	function Item({ item }) {
		return (
			<Paper>
				<CardMedia
					className={css.media}
					component="img"
					alt={item.title}
					height="140"
					image={item.imageUrl}
				/>
			</Paper>
		)
	}
	return (
		<Collapse in={animation} timeout={animation ? 1000 : 0} className={css.collapse}>
			<Card className={css.root}>
				<Carousel navButtonsAlwaysVisible duration={1500}>{plants.map((item, i) => <Item key={i} item={item} />)}</Carousel>
				<CardContent style={{ textAlign: 'center' }}>
					<Typography gutterBottom variant="h5" component="div" className={css.title}>
						{plants[0].title}
					</Typography>
					<Typography variant="body2" color="text.secondary" className={css.title}>
						{plants[0].desc}
					</Typography>
				</CardContent>
				<CardActions style={{ justifyContent: 'center' }}>
					{/* <Button size="small">Place Order</Button> */}
					<PayPal />
				</CardActions>
			</Card>
		</Collapse>
	);
}
export default ImageCard