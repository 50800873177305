import React from "react";
import { makeStyles } from '@mui/styles'
import ImageCard from "./ImageCard";
import useWindowPosition from "../hook/useWindowPosition";

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down("md")]: {
         flexDirection: 'column'
      }
   }
}))

const Plant = ({ Plants, PayPal }) => {
   const css = useStyles();
   const animation = useWindowPosition('body')
   return (
      <div className={css.root} id="plant-to-visit">
         <ImageCard plants={Plants} animation={animation} PayPal={PayPal} />
      </div>
   )
}
export default Plant;