const desc = 'Click on the button below to place your order'
const title = 'Joepii'
const basePath = process.env.PUBLIC_URL + '/assets/'
const Plants = [
   {
      title: title,
      desc: desc,
      imageUrl: basePath + 'plant1.png',
   },
   {
      title: title,
      desc: desc,
      imageUrl: basePath + 'plant2.png',
   }
]

export default Plants;